import create from 'zustand'

interface BearState {
  show_search_bar: boolean
  set_show_search_bar: (v: boolean) => void
}

export const use_global_state = create<BearState>()((set, get) => ({
    show_search_bar: false,
    set_show_search_bar: (v: boolean) => {
        set({show_search_bar: v});
    }
}))