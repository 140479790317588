import React, { useState } from 'react';
import { SearchInput } from "./UserInterface/UI_Comps/SearchInput";
// import ContentComponents from "./ContentComponents";


import hi from "../img/hi.svg"

// Group one
import eye from '../img/eye.svg'
import brand from '../img/brand.svg'
import colors from '../img/colors.svg'
import typography from '../img/typography.svg'
import Icons from '../img/icons.svg'
import Layouting from '../img/layouting.svg'
import Illustrations from '../img/illustration.svg'

// Group two
import userinterface from '../img/userinterface.svg'
import components from '../img/Components.svg'
import WorkingWithSvgs from '../img/WorkingWithSvgs.svg'
import UIAnimations from '../img/UIAnimations.svg'
import Responsive from '../img/Responsive.svg'
import Themes from '../img/Themes.svg'
import { useNavigate, Link } from 'react-router-dom';

const DesignSystem = () => {

    const navigate = useNavigate();

    return (

            <aside className="w-64 h-full pt-4 pl-4 pr-2">
                <div className="flex flex-col gap-2 items-start">

                    <div className='w-full hidden lg:block'>
                    <SearchInput />
                    </div>
                    {/* First Group */}
                    <h1 className='group py-2 pl-2 pr-2 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Introduction') }} >
                        <img className="w-4 " src={hi} alt="" />
                        <span className=" text-base font-medium font-fivo transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Introduction</span></h1>
                    <div className='flex flex-col items-start w-full divide-y-2 divide-zinc-10 cursor-pointer'>
                        <h1 className="group py-2 pl-2 pr-2 w-full inline-flex gap-4 items-center hover:bg-zinc-50" onClick={() => { navigate('/Design-System/Brand-Appearance') }} >
                            <img className="w-4" src={eye} alt="" />
                            <span className=" text-base font-medium font-fivo transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Broken Appearance</span></h1>
                        <ul className="w-full flex flex-col divide-y divide-zinc-100">
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Brand-Appearance/Identity') }}>
                                <img className="w-4" src={brand} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Brand Identity</span></li>
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Brand-Appearance/Colors') }}>
                                <img className="w-4" src={colors} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Colors</span></li>
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Brand-Appearance/Typography') }}>
                                <img className="w-4" src={typography} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Typography</span></li>
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Brand-Appearance/Layouting') }}>
                                <img className="w-4" src={Layouting} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Layouting</span></li>
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Brand-Appearance/Illustrations') }}>
                                <img className="w-4" src={Illustrations} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Illustrations</span></li>
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/Brand-Appearance/Icons') }}>
                                <img className="w-4" src={Icons} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Icons</span></li>
                        </ul>
                    </div>

                    {/* Second Group */}
                    <div className="flex flex-col items-start w-full divide-y-2 divide-zinc-100">
                        <h1 className="group py-2 pl-2 pr-2 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50" onClick={() => { navigate('/Design-System/UserInterface') }}>
                            <img className="w-4" src={userinterface} alt="" />
                            <span className=" text-base font-medium font-fivo transition-transform duration-100 ease-out group-hover:-translate-y-0.5">User Interface</span></h1>
                        <ul className="w-full flex flex-col divide-y divide-zinc-100">
                            {/* <li className="py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50">
                            <img className="w-4" src={brand} alt="" />
                            <span>UI Introduction</span></li> */}
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/UserInterface/Components') }}>
                                <img className="w-4" src={components} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Components</span></li>
                            <li className='group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50' onClick={() => { navigate('/Design-System/UserInterface/Responsive-UI') }}>
                                <img className="w-4" src={Responsive} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Responsive UI</span></li>
                            <li className="group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50" onClick={() => { navigate('/Design-System/UserInterface/Themes') }}>
                                <img className="w-4" src={Themes} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Themes</span></li>
                            <li className="group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50" onClick={() => { navigate('/Design-System/UserInterface/UIAnimations') }}>
                                <img className="w-4" src={UIAnimations} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">UI Animations</span></li>
                            <li className="group py-2 pl-8 w-full inline-flex gap-4 items-center cursor-pointer hover:bg-zinc-50" onClick={() => { navigate('/Design-System/UserInterface/Working-With-SVGs') }}>
                                <img className="w-4" src={WorkingWithSvgs} alt="" />
                                <span className="transition-transform duration-100 ease-out group-hover:-translate-y-0.5">Working with SVGs</span></li>
                        </ul>
                    </div>
                </div>
            </aside>
    )
}

export default DesignSystem;