import Look from "../img/Look.svg"

const ErrorPage = ()=> {
    return (
        <div className="flex flex-col items-center justify-center h-screen w-full gap-4">
                <img className="h-24" src={Look} alt="" />
                <h1 className="text-2xl font-bold font-fivo w-96 text-center">Oooops! This page doesn't exist.</h1>
        </div>
    )
}

export default ErrorPage;