const Textarea = (props: {textPlaceholder: string}) => {
    return (
        <div className="w-full">
            <textarea
                id="TextInput"
                placeholder={props.textPlaceholder}
                className="w-full appearance-none outline outline-zinc-100 hover:outline-4 hover:outline-zinc-100 focus:outline-2 focus:outline-violet-400 p-2 rounded-md border-gray-200 pr-10 shadow-sm sm:text-sm" />

            <span className="pointer-events-none absolute inset-y-0 right-0 w-10 place-content-center text-gray-500">

            </span>
        </div>
    )
}

export default Textarea;