import Peaches from "../img/Peaches.svg"

const LoadingPage = () => {
    return (
        <div className="w-full h-screen bg-zinc-500/10 flex flex-col justify-center items-center">
            <img className="animate-bounce" src={Peaches} alt="" />
            Hang on!
        </div>
    )
}

export default LoadingPage