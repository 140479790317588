import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { use_global_state } from "../../../global_state"

export const SearchInput = () => {
    const show_search_bar = use_global_state(s => s.show_search_bar);
    const set_show_search_bar = use_global_state(s => s.set_show_search_bar);

    // const [inp_classes, set_input_classes] = useState<string>("");

    const inp_ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (show_search_bar) {
            if (inp_ref.current) {
                inp_ref.current.focus();
            }
        }

    }, [show_search_bar])

    const on_keyup: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === "Escape") {
            set_show_search_bar(false);
            if (inp_ref.current) {
                inp_ref.current.value = "";
                inp_ref.current.blur();
            }
        }
        else if (e.key === "Enter") {

        }
    }

    const on_blur = () => {
        set_show_search_bar(false);
    }
    const on_focus = () => {
        set_show_search_bar(true);
    }

    const full_classes = show_search_bar?"scale-150 translate-x-24 ":"";

    return (
        <div className={"w-full " + full_classes}>
                <div className="relative group w-full inline-flex gap-1 items-center text-zinc-300 hover:text-zinc-500">
                    <input 
                        onFocus={on_focus} 
                        onBlur={on_blur} 
                        onKeyUp={on_keyup} 
                        ref={inp_ref} 
                        className={"w-full transition-all duration-75 ease-out px-2 py-1 rounded-md placeholder:text-zinc-300 hover:placeholder:text-zinc-500 border-none outline outline-zinc-100 hover:outline-4 hover:outline-zinc-100 focus:outline focus:outline-2 focus:outline-violet-400 focus:text-zinc-900 " } 
                        type="text" typeof="search" name="search" placeholder="Press / to search" />
                    <label htmlFor="search">
                        <svg className="pointer-events-none group-focus:hidden group-hover:text-zinc-500 absolute top-[20%] right-[5%] h-5" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8333 22.1667C17.988 22.1667 22.1667 17.988 22.1667 12.8333C22.1667 7.67868 17.988 3.5 12.8333 3.5C7.67868 3.5 3.5 7.67868 3.5 12.8333C3.5 17.988 7.67868 22.1667 12.8333 22.1667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M24.5 24.5L19.425 19.425" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </label>
                </div>





        </div>
    )
}