import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import DesignSystem from './DesignSystem';

export const Header = () => {
    const navigate = useNavigate();
    const [show_navbar, set_show_navbar] = useState<boolean>(false);
    const [show_sidebar, set_show_sidebar] = useState<boolean>(false);
    // const [darkToggle, setDarkToggle] = React.useState(true)
    // Add this to the classes {' here '}
    // ${darkToggle && 'dark'}
    return (
        <div className="w-full border border-zinc-100 transition-all">
            <nav className="inline-flex flex-nowrap items-center w-full justify-between px-3 sm:px-8 py-2 dark:bg-zinc-900">

                <div className='inline-flex gap-4'>

                    {/* Hamburger menu for navbar */}
                    <div onClick={() => set_show_sidebar(p => !p)} className='lg:hidden cursor-pointer inline-flex h-full items-center justify-center rounded-md border border-gray-100 px-2 py-1 text-gray-600 hover:bg-gray-50 hover:text-gray-700'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 12H21" stroke="#312F2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 6H21" stroke="#312F2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 18H21" stroke="#312F2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>

                    <div className="cursor-pointer inline-flex gap-4 items-center" onClick={() => { navigate('/') }}>
                        <svg className="h-8 text-black dark:text-white" width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.63956 0H3.95143L0 22.1012H9.10838C11.1511 22.0566 15.5043 20.6814 16.5759 15.5378C17.9154 9.10838 13.2607 7.09917 10.9167 7.09917H7.46753L8.63956 0ZM8.29744 17.1622C8.64401 17.3207 9.01835 17.406 9.398 17.4131C11.1133 17.4131 12.5367 15.8198 12.5207 13.9119C12.5391 13.5355 12.481 13.1593 12.3499 12.8071C12.2188 12.4548 12.0177 12.1342 11.7591 11.8655C11.5006 11.5967 11.1904 11.3856 10.848 11.2456C10.5055 11.1056 10.1384 11.0397 9.76984 11.0521C8.92497 11.0692 8.12109 11.4273 7.53375 12.0481C6.94642 12.6689 6.62337 13.502 6.63516 14.3653C6.61301 14.7527 6.66768 15.1406 6.79588 15.5058C6.92408 15.871 7.12317 16.2059 7.3812 16.4906C7.63923 16.7752 7.95088 17.0036 8.29744 17.1622Z" fill="currentColor" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M25.9623 0H30.6504L34.6019 22.1012H25.4935C23.4508 22.0566 19.0975 20.6814 18.026 15.5378C16.6865 9.10838 21.3411 7.09917 23.6852 7.09917H27.1343L25.9623 0ZM26.3044 17.1622C25.9579 17.3207 25.5835 17.406 25.2039 17.4131C23.4886 17.4131 22.0652 15.8198 22.0812 13.9119C22.0627 13.5355 22.1209 13.1593 22.252 12.8071C22.3831 12.4548 22.5842 12.1342 22.8427 11.8655C23.1012 11.5967 23.4115 11.3856 23.7539 11.2456C24.0963 11.1056 24.4634 11.0397 24.832 11.0521C25.6769 11.0692 26.4808 11.4273 27.0681 12.0481C27.6554 12.6689 27.9785 13.502 27.9667 14.3653C27.9889 14.7527 27.9342 15.1406 27.806 15.5058C27.6778 15.871 27.4787 16.2059 27.2207 16.4906C26.9626 16.7752 26.651 17.0036 26.3044 17.1622Z" fill="currentColor" />
                            <circle className='text-violet-500 dark:text-violet-400' cx="17.3009" cy="23.076" r="2.9241" fill="currentColor" />
                        </svg>
                        <div>
                            <span className="font-fivo font-medium text-xl dark:text-white">broken.</span>
                            <span className="font-fivo font-medium text-xl text-violet-500 dark:text-violet-300">design</span>
                        </div>
                    </div>
                </div>

                {show_sidebar &&
                        <div className='z-[60] lg:hidden fixed flex flex-col left-0 top-0 h-[calc(100vh-2rem)] py-4 px-4 border-2 border-zinc-100 rounded-lg bg-white/80 backdrop-blur-xl shadow-xl'>
                            <div onClick={() => set_show_sidebar(p => !p)} className='cursor-pointer w-10 h-10 z-[70] md:left-9 inline-flex items-center justify-center rounded-md border border-white px-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700'>
                                <svg className='h-6 w-6' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.9497 5.05029L5.05024 14.9498" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.05026 5.05029L14.9498 14.9498" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <DesignSystem />
                        </div>
                    }
                <div className="lg:inline-flex gap-6 items-center hidden">
                    {/* <ThemeToggle /> */}
                    <div ><svg name="toggle_small" className="w-12 group cursor-pointer text-zinc-200  hover:text-zinc-700" viewBox="0 0 58 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle className="group-hover:-translate-x-7 transition-transform ease-in-out duration-75" cx="43" cy="15" r="15" fill="currentColor" />
                        <g className='-translate-y-[0.03rem]  -translate-x-[0.2rem]' clipPath="url(#clip0_726_5886)">
                            <path d="M41.9998 19.3333C43.8408 19.3333 45.3332 17.8409 45.3332 16C45.3332 14.1591 43.8408 12.6667 41.9998 12.6667C40.1589 12.6667 38.6665 14.1591 38.6665 16C38.6665 17.8409 40.1589 19.3333 41.9998 19.3333Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M42 8.66667V10" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M42 22V23.3333" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M36.8135 10.8133L37.7601 11.76" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M46.2402 20.24L47.1869 21.1867" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M34.6665 16H35.9998" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M48 16H49.3333" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M36.8135 21.1867L37.7601 20.24" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M46.2402 11.76L47.1869 10.8133" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <path className='-translate-x-[0.2rem] -translate-y-[0.05rem]' d="M22.7721 19.6445C23.4692 18.7429 23.8951 17.6615 23.9999 16.5267C23.1011 17.1911 21.9936 17.5108 20.879 17.4276C19.7643 17.3445 18.7166 16.8641 17.9262 16.0737C17.1358 15.2834 16.6554 14.2356 16.5723 13.1209C16.4892 12.0063 16.8089 10.8988 17.4733 10C16.3385 10.1049 15.257 10.5308 14.3554 11.2278C13.4538 11.9249 12.7694 12.8643 12.3822 13.9362C11.9951 15.0081 11.9212 16.168 12.1692 17.2803C12.4172 18.3927 12.9769 19.4113 13.7828 20.2172C14.5886 21.023 15.6073 21.5827 16.7196 21.8307C17.8319 22.0787 18.9919 22.0049 20.0637 21.6177C21.1356 21.2305 22.075 20.5461 22.7721 19.6445Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />

                        <rect x="1" y="2" width="50" height="26" rx="13" stroke="currentColor" strokeWidth="2" />
                    </svg></div>

                    <div className='font-fivo text-base cursor-pointer text-zinc-600 hover:text-violet-600 transition-all duration-100 ease-out' onClick={() => { navigate('/Experiments') }} >Experiments</div>
                    <div className='font-fivo text-base cursor-pointer text-zinc-600 hover:text-violet-600 transition-all duration-100 ease-out' onClick={() => { navigate('/Design-System') }} >Design System</div>
                    <div className='font-fivo text-base cursor-pointer text-zinc-600 hover:text-violet-600 transition-all duration-100 ease-out' onClick={() => { navigate('/Design-System/Brand-Appearance/Icons') }} >Icons Library</div>
                    <div className="font-fivo text-base cursor-pointer text-zinc-600 hover:text-violet-600 transition-all duration-100 ease-out" onClick={() => { navigate('/Blogs') }}>Blogs</div>
                    <div className="font-fivo text-base cursor-pointer text-zinc-600 hover:text-violet-600 transition-all duration-100 ease-out">v0.1</div>

                    <svg className="h-6 cursor-pointer text-zinc-600 hover:text-violet-600 transition-all duration-100 ease-out" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_626_5690)">
                            <path d="M9 19.0001C4 20.5001 4 16.5001 2 16.0001M16 22.0001V18.1301C16.0375 17.6532 15.9731 17.1739 15.811 16.7239C15.6489 16.2738 15.3929 15.8635 15.06 15.5201C18.2 15.1701 21.5 13.9801 21.5 8.52006C21.4997 7.12389 20.9627 5.78126 20 4.77006C20.4559 3.54857 20.4236 2.19841 19.91 1.00006C19.91 1.00006 18.73 0.65006 16 2.48006C13.708 1.85888 11.292 1.85888 9 2.48006C6.27 0.65006 5.09 1.00006 5.09 1.00006C4.57638 2.19841 4.54414 3.54857 5 4.77006C4.03013 5.78876 3.49252 7.14352 3.5 8.55006C3.5 13.9701 6.8 15.1601 9.94 15.5501C9.611 15.89 9.35726 16.2955 9.19531 16.74C9.03335 17.1845 8.96681 17.6581 9 18.1301V22.0001" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_626_5690">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>


                <div className='inline-flex gap-2 lg:hidden'>

                    {/* Search Icon */}
                    <div className="hover:bg-zinc-100 inline-flex items-center border border-gray-100 px-2 rounded-md text-zinc-500 hover:text-zinc-800 cursor-pointer"><svg className="pointer-events-none group-hover:text-zinc-500 h-5" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8333 22.1667C17.988 22.1667 22.1667 17.988 22.1667 12.8333C22.1667 7.67868 17.988 3.5 12.8333 3.5C7.67868 3.5 3.5 7.67868 3.5 12.8333C3.5 17.988 7.67868 22.1667 12.8333 22.1667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24.5 24.5L19.425 19.425" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></div>

                    <div className="inline-flex items-stretch rounded-md bg-white">

                        {/* <a href="/edit" className="rounded-l-md px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-700"> Edit </a> */}

                        <div className="relative">

                            {/* Three Dots icon for expanding menu */}
                            <button onClick={() => set_show_navbar(p => !p)} type="button" className="z-50 inline-flex h-full items-center justify-center rounded-md border border-gray-100 px-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700">
                                <span className="sr-only">Menu</span>
                                <svg className='h-6 w-6' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#312F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#312F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#312F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>

                            {show_navbar && <div className='transition-all duration-100 ease-out'>
                                <div onClick={() => set_show_navbar(p => !p)} className='cursor-pointer fixed top-3 py-1 right-3 z-[70] md:right-9 inline-flex items-center justify-center rounded-md border border-white px-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700'>
                                    <svg className='h-6 w-6' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.9497 5.05029L5.05024 14.9498" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5.05026 5.05029L14.9498 14.9498" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div id='navmenu' className="z-[70] fixed right-3 md:right-9 mt-2 w-56 origin-top-right rounded-xl border border-gray-100 bg-white shadow-xl" role="menu">
                                    <div className="p-2">
                                        <div className='font-fivo block rounded-lg px-4 py-2 text-sm hover:bg-gray-50 cursor-pointer text-zinc-500 hover:text-violet-600 transition-all duration-100 ease-out' onClick={() => { navigate('/Experiments') }} >Experiments</div>
                                        <div className='font-fivo block rounded-lg px-4 py-2 text-sm hover:bg-gray-50 cursor-pointer text-zinc-500 hover:text-violet-600 transition-all duration-100 ease-out' onClick={() => { navigate('/Design-System') }} >Design System</div>
                                        <div className='font-fivo block rounded-lg px-4 py-2 text-sm hover:bg-gray-50 cursor-pointer text-zinc-500 hover:text-violet-600 transition-all duration-100 ease-out' onClick={() => { navigate('/Design-System/Brand-Appearance/Icons') }} >Icons Library</div>
                                        <div className="font-fivo block rounded-lg px-4 py-2 text-sm hover:bg-gray-50 cursor-pointer text-zinc-500 hover:text-violet-600 transition-all duration-100 ease-out" onClick={() => { navigate('/Blogs') }}>Blogs</div>
                                        <div className="font-fivo block rounded-lg px-4 py-2 text-sm hover:bg-gray-50 cursor-pointer text-zinc-500 hover:text-violet-600 transition-all duration-100 ease-out">v0.1</div>
                                    </div>
                                </div>
                                <div onClick={() => set_show_navbar(p => !p)} className='fixed transition-all duration-100 ease-out h-screen w-full top-0 left-0 bg-zinc-300/30 backdrop-blur-sm z-[60]'>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

            </nav>
            {/* <div className='w-full'>
                <svg className='w-full' width="1440" height="20" viewBox="0 0 1440 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M-41 19C86.8119 -2.36437 461.627 -2.06098 666.272 6.29264C895.099 15.6334 1255.75 19.0928 1481 4.55511" stroke="#D0D5DD" />
                </svg>
            </div> */}
        </div>
    )
}