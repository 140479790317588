// function ButtonBase = (props: {className: string, size:string, as: string, children: any, IconStart: any, IconEnd: any})=> {
//     const {
//         className,
//         size = 'base',
//         as = 'button', // let Ana render it as a button or an anchor.
//         children,
//         IconStart, // use to render an icon before children
//         IconEnd, // use to render an icon after children
//         ...restProps
//     } = props;

//     const Element = as;

//     return (
//         <Element
//             {...restProps}
//             // use size, className prop to generate className for this element
//             className="generated-base-classnames"
//         >
//             <span>{IconStart}</span>
//             {children}
//             <span>{IconEnd}</span>
//         </Element>
//     );
// }

// function ButtonSolid = (props)=> {
//     const { variant = 'primary', ...restProps } = props;
//     return (
//         <ButtonBase
//             // all the other props like IconStart will be passed to ButtonBase
//             {...restProps}
//             // use variant to generate className and pass it to ButtonBase
//             className="generated-solid-classnames"
//         />
//     );
// }

// function ButtonGhost(props) {
//     const { variant = 'primary', ...restProps } = props;
//     return (
//         <ButtonBase
//             // all the other props like IconStart will be passed to ButtonBase
//             {...restProps}
//             // use variant to generate className and pass it to ButtonBase
//             className="generated-ghost-classnames"
//         />
//     );
// }

// export function IconButton(props) {
//     const {
//         className,
//         size = 'base',
//         as = 'button', // let Ana render it as a button or an anchor.
//         Icon,
//         tooltip, // button text in tooltip (code not shown)
//         ...restProps
//     } = props;

//     const Element = as;

//     return (
//         <Element
//             {...restProps}
//             // use size, className prop to generate className for this element
//             className={`generated-icon-classnames ${className}`}
//         >
//             {Icon}
//         </Element>
//     );
// }

const ButtonApp = (props: {Variant: string, Text: string, Iconleft: any, IconRight: any})=> {
    const {Variant} = props;

    const Primary = {
        background_color : "bg-violet-500 ",
        text_color : "text-white ",
        shadow : "hover:shadow-lg hover:shadow-violet-200 ",
        transform : "hover:-translate-y-0.5 "
    };

    const Secondary = {
        background_color : "bg-violet-50 hover:bg-violet-100 ",
        text_color : "text-violet-700 ",
        transform : "hover:-translate-y-0.5 "
    };

    const Link = {
        background_color : "bg-white ",
        text_color : "text-violet-500 ",
        transform : "hover:-translate-y-0.5 "
    };

    // const Variant = "";

    const PB = Primary.background_color + Primary.text_color + Primary.shadow + Primary.transform;
    const SB = Secondary.background_color + Secondary.text_color + Secondary.transform;
    const LB = Link.background_color + Link.text_color+ Link.transform;

    let classes = "";
    if(Variant === "PB") classes = PB; 
    if(Variant === "SB") classes = SB; 
    if(Variant === "LB") classes = LB; 

    return (
        <button className={"inline-flex gap-2 rounded-full w-fit px-6 py-3 items-center text-base font-medium transition-all duration-100 ease-out  " + classes}>
            <img src={props.Iconleft}></img>
            <span>{props.Text}</span>
            <img src={props.IconRight}></img>
        </button>
    )
}

export default ButtonApp;