import ButtonApp from "./ButtonApp"
import Textarea from "./Textarea"

import thumbsup from '../../img/thumbsup.svg'
import thumbsdown from '../../img/thumbsdown.svg'

const FeedbackForm = () => {
    return (

        <div className='flex flex-col items-center gap-8 py-32'>
            <div className='inline-flex items-center max-w-3xl'>
                <span className='h-0.5 block w-96 bg-zinc-100'></span>
                <span className='w-fit text-center px-10 whitespace-nowrap text-lg font-semibold text-zinc-600 tracking-wide'>Any Feedback to Share</span>
                <span className='h-0.5 block w-96 bg-zinc-100'></span>
            </div>

            <div className='inline-flex items-center max-w-lg gap-10'>
                <div className='group p-6 w-20 h-20 bg-zinc-50 rounded-full cursor-pointer hover:bg-teal-100'>
                    <img className='text-teal-400 w-full h-full transition-transform duration-100 ease-in-out group-hover:-translate-y-1.5 group-hover:-rotate-3' src={thumbsup} alt="" />
                </div>
                <div className='group p-6 w-20 h-20 bg-zinc-50 rounded-full cursor-pointer hover:bg-rose-100'>
                    <img className='text-rose-400 w-full h-full transition-transform duration-100 ease-in-out group-hover:translate-y-1.5 group-hover:-rotate-3' src={thumbsdown} alt="" />
                </div>
            </div>

            <div className='w-96 flex flex-col gap-3 items-center'>
                <Textarea textPlaceholder='What do you think?' />
                <ButtonApp Variant='PB' Text='Submit' IconRight={" "} Iconleft={" "} />
            </div></div>
    )
}

export default FeedbackForm;