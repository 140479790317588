import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './comps/Header';

import { createBrowserRouter, RouterProvider, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { use_global_state } from './global_state';
import ErrorPage from './comps/Error';
import LoadingPage from './comps/LoadingPage';

import Textarea from './comps/ThisAppUI/Textarea';
import ButtonApp from './comps/ThisAppUI/ButtonApp';
import FeedbackForm from './comps/ThisAppUI/FeedbackForm';

// header

const DesignSystem = React.lazy(() => import("./comps/DesignSystem"));
const Home = React.lazy(() => import("./comps/Home"));
const Blogs = React.lazy(() => import("./comps/Blogs"));


// Brand Appearance 
const Introduction = React.lazy(() => import("./comps/Introduction"));

const BrandAppearance = React.lazy(() => import("./comps/BrandAppearance"));
const BrandIdentity = React.lazy(() => import("./comps/BrandAppearance/BrandIdentity"));
const Colors = React.lazy(() => import("./comps/BrandAppearance/Colors"));
const Typography = React.lazy(() => import("./comps/BrandAppearance/Typography"));
const Layouting = React.lazy(() => import("./comps/BrandAppearance/Layouting"));
const Illustrations = React.lazy(() => import("./comps/BrandAppearance/Illustrations"));
const Icons = React.lazy(() => import("./comps/Icons"));

const UserInterface = React.lazy(() => import("./comps/UserInterface"));
const Components = React.lazy(() => import("./comps/UserInterface/Components"));
const ResponsiveUI = React.lazy(() => import("./comps/UserInterface/ResponsiveUI"));
const Themes = React.lazy(() => import("./comps/UserInterface/Themes"));
const UIAnimations = React.lazy(() => import("./comps/UserInterface/UIAnimations"));
const WorkingWithSvgs = React.lazy(() => import("./comps/UserInterface/WorkingWithSvgs"));



const ChildComp = () => {
  const [crumbs, set_crumbs] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (!location) return;
    const crumbs = location.pathname.split("/").filter(a => a);
    set_crumbs(crumbs);
  }, [location]);



  return (
    <div className='h-screen'>
      <Header />
      <div className='transition-all duration-100 ease-out flex h-[calc(100vh-3.4rem)] overflow-hidden flex-row sm:divide-x sm:divide-zinc-100'>
        
        <div className='w-fit h-full max-h-screen hidden lg:block transition-all duration-100 ease-out'>
          <DesignSystem />
        </div>

        <div className='flex flex-col gap-1 w-full overflow-y-scroll'>
          
          <div className='sticky top-0 bg-white z-50'>
            {crumbs.map((c, i) => (
              <button key={c}
                onClick={(e) => { let p = crumbs.slice(1, i + 1).join("/"); console.log(crumbs, "----", p, i); navigate(p) }}
                className='px-2 py-1 text-xs cursor-pointer inline-flex items-center gap-2 text-zinc-700 hover:text-black hover:underline hover:underline-offset-2'><svg className='h-2' width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 11.5L6.5 6.5L1.5 1.5" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {c}
              </button>
            ))}
          </div>

          <Outlet />


          {/* Navigation Buttons */}
          <div className='inline-flex justify-between w-full px-12'>

            <div className='flex flex-col gap-2 items-start'>
              <ButtonApp Variant='SB' Text='Previous' IconRight={" "} Iconleft={" "} />
              <span className='text-sm'>1.1 Previous Page Name Here</span>
            </div>
            <div className='flex flex-col gap-2 items-end'>
              <ButtonApp Variant='SB' Text='Next' IconRight={" "} Iconleft={" "} />
              <span className='text-sm'>1.3 Next Page Name Here</span>
            </div>
          </div>

          <FeedbackForm />




        </div>
      </div>
    </div>)
}

const BHome = () => {


  return (
    <div className='w-full'>
      <Header />
      <Home />
    </div>
  )
}

const Loading = () => <LoadingPage />;

const router = createBrowserRouter([
  {
    path: "/",
    element: <BHome />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Design-System",
    element: <ChildComp />,
    children: [
      {
        path: "Brand-Appearance",
        element: <BrandAppearance />,
        children: [
          {
            path: "Identity",
            element: <BrandIdentity />
          },
          {
            path: "Colors",
            element: <Colors />
          },
          {
            path: "Layouting",
            element: <Layouting />
          },
          {
            path: "Typography",
            element: <Typography />
          },
          {
            path: "Illustrations",
            element: <Illustrations />
          },
          {
            path: "Icons",
            element: <Icons />
          },
        ]
      },
      {
        path: "Introduction",
        element: <Introduction />
      },
      {
        path: "UserInterface",
        element: <UserInterface />,
        children: [
          {
            path: "Components",
            element: <Components />
          },
          {
            path: "Responsive-UI",
            element: <ResponsiveUI />
          },
          {
            path: "Themes",
            element: <Themes />
          },
          {
            path: "UIAnimations",
            element: <UIAnimations />
          },
          {
            path: "Working-With-SVGs",
            element: <WorkingWithSvgs />
          },
        ]

      },
      // ,
      // {
      //   path: "Brand-Appearance/Identity",
      //   element: <BrandIdentity />,
      // },
      // {
      //   path: "Brand-Appearance/Colors",
      //   element: <Colors />
      // },
      // {
      //   path: "Brand-Appearance/Typography",
      //   element: <Typography />
      // },
      // {
      //   path: "Brand-Appearance/Layouting",
      //   element: <Layouting />
      // },
      // {
      //   path: "Brand-Appearance/Illustrations",
      //   element: <Illustrations />
      // },
      // {
      //   path: "Brand-Appearance/Icons",
      //   element: <Icons />
      // }
    ]
  }
]);

const GET_GS = use_global_state.getState;
const SET_GS = use_global_state.setState;
function App() {



  // const set_show_search_bar = use_global_state(s=>s.set_show_search_bar);


  useEffect(() => {
    const forward_slash_listener = (e: KeyboardEvent) => {
      if (e.key === "/") {
        SET_GS({ show_search_bar: true });
      }
    }

    document.addEventListener("keyup", forward_slash_listener)

    return () => document.removeEventListener("keyup", forward_slash_listener);

  }, [])


  return (
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </React.Suspense>
    </React.StrictMode>

  );
}

export default App;
